import logo from './logo.svg';
import './App.css';
import {Routes, Route, Link, BrowserRouter} from 'react-router-dom';
import HomePage from './Pages/Home';
import LexisWorld from "./Pages/LexisWorld";
import LexiPrivacy from "./Pages/LexisWorldPrivacy";
import LexiTerms from "./Pages/LexisWorldTerms";
import LexiIdea from "./Pages/LexiIdea";
import AFewWords from "./Pages/AFewWords";
import AFewWordsPrivacy from "./Pages/AFewWordsPrivacy";
import AFewWordsTerms from "./Pages/AFewWordsTerms";

function App() {
  return (
            <div className="App">
              <Routes>
                <Route path="/" element={<HomePage />} />
                  <Route path={"/lexis-world"} element={<LexisWorld />} />
                  <Route path={"/lexi_privacy"} element={<LexiPrivacy />} />
                  <Route path={"/lexi_terms"} element={<LexiTerms />} />
                  <Route path={"/a-few-words"} element={<AFewWords />} />
                  <Route path={"/a-few-words-privacy"} element={<AFewWordsPrivacy />} />
                  <Route path={"/a-few-words-terms"} element={<AFewWordsTerms />} />
                <Route path={"/lexi_idea"} element={<LexiIdea />} />
              </Routes>
            </div>
  );
}

export default App;
