import React from "react";
import LexiFooter from "../Components/LexisWorldFooter";

function LexiTerms() {
    return (<main className={`flex flex-col items-center w-full font-Inter p-3`}>
            <section className={`max-w-xl w-full`}>
                <a href={"./lexis-world"}><div className="flex flex-col items-center w-full aspect-video bg-[url('./images/LexisWorldBanner.png')] bg-cover bg-center bg-amber-400 mb-4" /></a>

                <div className="text-left w-full">
                    <p className={`text-lg font-semibold w-full mb-4`}>Terms of service for <i>Lexi's World</i></p>
                    <p className={`text-base  w-full mb-4`}>
                        <strong>Terms</strong><br/>
                        By accessing our app, <i>Lexi's World</i>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing <i>Lexi's World</i>. The materials contained in <i>Lexi's World</i> are protected by applicable copyright and trademark law.
                    </p>
                    <p className={`text-base  w-full mb-4`}>
                        <strong>Use License</strong><br/>
                        Permission is granted to temporarily download one copy of <i>Lexi's World</i> per device for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:<br/>
                        modify or copy the materials;
                        <ul>
                            <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                            <li>attempt to decompile or reverse engineer any software contained in <i>Lexi's World</i>;</li>
                            <li>remove any copyright or other proprietary notations from the materials; or</li>
                            <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                        </ul>
                        This license shall automatically terminate if you violate any of these restrictions and may be terminated by Pop Pop Pop LLC at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
                    </p>
                    <p className={`text-base  w-full mb-4`}>
                        <strong>Access to <i>Lexi's World</i> Content</strong><br/>
                        Access to <i>Lexi's World</i> content requires one to purchase the app for a one time fee or for a paid subscription. These options are accessible from the <i>Lexi's World</i> application. Pop Pop Pop LLC may offer a subscription to a free trial period that allows the user to access all of <i>Lexi's World</i> content in the application free of charge for a fixed period of time. The subscription will then become paid the day after the free trial period expires.
                    </p>
                    <p className={`text-base  w-full mb-4`}>
                        <strong>Disclaimer</strong><br/>
                        The material within <i>Lexi's World</i> is provided on an 'as is' basis. Pop Pop Pop LLC makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                        Further, Pop Pop Pop LLC does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to <i>Lexi's World</i>.
                    </p>
                    <p className={`text-base  w-full mb-4`}>
                        <strong>Limitations</strong><br/>
                        In no event shall Pop Pop Pop LLC or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use <i>Lexi's World</i>, even if Pop Pop Pop LLC or a Pop Pop Pop LLC authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
                    </p>
                    <p className={`text-base  w-full mb-4`}>
                        <strong>Accuracy of materials</strong><br/>
                        The materials appearing in <i>Lexi's World</i> could include technical, typographical, or photographic errors. Pop Pop Pop LLC does not guarantee that any of the materials on <i>Lexi's World</i> are accurate, complete or current. Pop Pop Pop LLC may make changes to the material contained in <i>Lexi's World</i> at any time without notice. However Pop Pop Pop LLC does not make any commitment to update the materials.
                    </p>
                    <p className={`text-base  w-full mb-4`}>
                        <strong>Links</strong><br/>
                        Pop Pop Pop LLC has not reviewed all of the sites linked to its app and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Pop Pop Pop LLC of the site. Use of any such linked website is at the user's own risk.
                    </p>
                    <p className={`text-base  w-full mb-4`}>
                        <strong>Modifications</strong><br/>
                        Pop Pop Pop LLC may revise these terms of service for its app at any time without notice. By using <i>Lexi's World</i> you are agreeing to be bound by the then current version of these terms of service.
                    </p>
                    <p className={`text-base  w-full mb-4`}>
                        <strong>Governing law</strong><br/>
                        These terms and conditions are governed by and construed in accordance with the laws of the United States of America and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
                    </p>




                </div>
            </section>

            {/*    FOOTER    */}
            <LexiFooter />

        </main>
    );
}

export default LexiTerms;
