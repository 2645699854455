import React from 'react'
import PopPopPopLogo from "../Icons/poppoppop-logo";
import Card from "../Components/card";
import CardLexi from "../Components/card-lexi";
import CardAFewWords from "../Components/card-a-few-words";
import Envelope from "../Components/Envelope";

export default function HomePage () {
 return (
   <main className={`flex flex-col items-center w-full font-Inter`}>
     <PopPopPopLogo />
       <Card link={"./lexis-world"}><CardLexi/></Card>
       <Card link={"./a-few-words"}><CardAFewWords /></Card>
       <a href={"mailto:contact@poppoppop.info"} target={"_blank"}>
       <div className={"flex flex-row justify-center items-center gap-3 my-16 cursor-pointer hover:underline"}>
        <div className={"w-12"}><Envelope /></div>
        <div>Email contact@poppoppop.info</div>
       </div>
       </a>

   </main>
 )
}