import React from 'react';
import Pop from './pop.js';

export default function PopPopPopLogo () {
    return (
      // <div className={`inline-flex my-[3vw] mx-[2vw] gap-[1.2vw]`} >
      <div className={`inline-flex my-12 w-4/5 max-w-lg relative`} >
        <Pop color="#FBB03B" /><Pop color="#6FCCDD" /><Pop color="#7EC247" />
      </div>
    );
}

