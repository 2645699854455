import { css, keyframes } from 'pretty-lights';
import { padding} from './variables';
import imgSrcLexiTitle from "../images/LexisWorldBanner.png";
import LexiIdeaTitleImage from "../images/idea_assets/idea_title_001.svg";
import LexiIdeaPanel1MeTBGImage from "../images/idea_assets/Idea_Panel_1_Me_ThumbsBG.svg";
import LexiIdeaPanel1MeTUImage from "../images/idea_assets/Idea_Panel_1_Me_ThumbsUp.svg";
import LexiIdeaPanel1MeTDImage from "../images/idea_assets/Idea_Panel_1_Me_ThumbsDown.svg";
import LexiIdeaPanel1A1Image from "../images/idea_assets/Idea_Panel_1_Aroha_1.svg";
import LexiIdeaPanel1A1aImage from "../images/idea_assets/Idea_Panel_1_Aroha_1a.svg";
import LexiIdeaPanel1A2Image from "../images/idea_assets/Idea_Panel_1_Aroha_2.svg";
import LexiIdeaPanel1A3Image from "../images/idea_assets/Idea_Panel_1_Aroha_3.svg";
import LexiIdeaPanel2_1Image from "../images/idea_assets/Idea_Panel_2_Me.svg";
import LexiIdeaPanel2_2Image from "../images/idea_assets/Idea_Panel_2_Aroha.svg";
import LexiIdeaPanel3aImage from "../images/idea_assets/Idea_Panel_3a.svg";
import LexiIdeaPanel3bImage from "../images/idea_assets/Idea_Panel_3b.svg";
import LexiIdeaPanel3cImage from "../images/idea_assets/Idea_Panel_3c.svg";
import LexiIdeaPanel3dImage from "../images/idea_assets/Idea_Panel_3d.svg";
import LexiIdeaPanel3eImage from "../images/idea_assets/Idea_Panel_3e.svg";
import LexiIdeaPanel3fImage from "../images/idea_assets/Idea_Panel_3f.svg";
import LexiIdeaPanel3gImage from "../images/idea_assets/Idea_Panel_3g.svg";
import LexiIdeaPanel4aImage from "../images/idea_assets/Idea_Panel_4a.svg";
import LexiIdeaPanel4bImage from "../images/idea_assets/Idea_Panel_4b.svg";
import LexiIdeaPanel4cImage from "../images/idea_assets/Idea_Panel_4c.svg";
import LexiIdeaPanel4dImage from "../images/idea_assets/Idea_Panel_4d.svg";
import LexiIdeaPanel4eImage from "../images/idea_assets/Idea_Panel_4e.svg";
import LexiIdeaPanel4fImage from "../images/idea_assets/Idea_Panel_4f.svg";
import LexiIdeaPanel5Image from "../images/idea_assets/Idea_Panel_5.svg";
import LexiIdeaPanel6Image from "../images/idea_assets/Idea_Panel_6.svg";
import LexiIdeaPanel7aImage from "../images/idea_assets/Idea_Panel_7a.svg";
import LexiIdeaPanel7bImage from "../images/idea_assets/Idea_Panel_7b.svg";
import LexiIdeaPanel8aImage from "../images/idea_assets/Idea_Panel_8a.svg";
import LexiIdeaPanel8bImage from "../images/idea_assets/Idea_Panel_8b.svg";
import LexiIdeaPanel8cImage from "../images/idea_assets/Idea_Panel_8c.svg";
import LexiIdeaPanel8dImage from "../images/idea_assets/Idea_Panel_8d.svg";
import LexiIdeaPanel8eImage from "../images/idea_assets/Idea_Panel_8e.svg";
import LexiIdeaPanel8fImage from "../images/idea_assets/Idea_Panel_8f.svg";
import LexiIdeaPanel9Image from "../images/idea_assets/Idea_Panel_9.svg";
import LexiIdeaPanel10Image from "../images/idea_assets/Idea_Panel_10.svg";

// eslint-disable-next-line import/prefer-default-export
export const paragraphClass = css`
  & + & {
    padding-top: ${padding}px;
  }
`;

export const headerClass = css`
  text-align: center;
`;

export const lexiBannerClass = css`
  display: block;
  width: 100%;
  height: 40vw;
  margin: auto;
  background-image: url(${imgSrcLexiTitle});
  background-size: cover;

`;

export const iPhoneImageWrapClass = css`
  width: 80%;
  max-width: 300px;
  position: relative;
  margin: auto;
  text-align:center;
`;

export const iPhoneImageClass = css`
  width: 100%;
  border-radius: 2.5vw;

`;

export const appstoreGoogleDownloadClass = css`
  width: 90%;
  margin: 0 auto 5vw auto;
`;

export const appstoreDownloadClass = css`
  width: 80%;
  margin: 3vw auto;
`;

export const topContentClass = css`
  position:relative;
  // overflow:hidden;
  max-width: 700px;
  margin: auto;
`;

export const videoWrapClass = css`
  clear:both;
  width:100%;
  height: 0;
  padding-bottom: Calc(75% * 1);
  position:relative;
  overflow:hidden;
`;

export const videoClass = css`
  position:absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const comingSoonClass = css`
  font-size: 1.5vw;
  width:80%;
  text-align:center;
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    font-size: .6em;
  }
`;

export const panelWrapper = css`
  position: relative;
  min-height: 85vh;
  margin: 0 auto 10vh auto;
  width:40vh;
`;

export const videoWrapper = css`
  position: relative;
  height: 71.1vh;
  margin: 0 auto 10vh auto;
  width:40vh;
`;

export const shortPanelWrapper = css`
  min-height: 7vh;
  border: 1px black solid;
  padding: 2vh;
  box-sizing: border-box;
`;

export const clickable = css`
  cursor: pointer;
`;
export const appstoreBadgeWrapper = css`
  margin:auto;
  width: 30vh;
  text-align: center;
`;

export const panelProgressBarWrapper = css`
  position: absolute;
  left:2vh;
  width: 36vh;
  bottom: 1vh;
  height: .25vh;
`;

export const panelProgressBar = css`
  position: absolute;
  left:0;
  top:0;
  background-color: #CCC;
  height:100%;
`;


export const panelImageClass = css`
  border: 1px solid #000000;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 85vh;
  width:100%;
  position: absolute;
  transition: opacity .5s ease-in-out;
`;

export const hiddenImageClass = css`
  visibility: hidden;
`;

export const panel0Title = css`
  background-image: url(${LexiIdeaTitleImage});
`;

export const stickyPanel = css`
  position:sticky;
  top: 7vh;
`;

export const test = css`
  width: 100%;
  background-color: aliceblue;
` ;

export const fadeUpAndDown = keyframes`
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

export const panel1MeThumbsBG = css`
  background-image: url(${LexiIdeaPanel1MeTBGImage});
`;

export const panel1MeThumbsUp = css`
  background-image: url(${LexiIdeaPanel1MeTUImage});
  animation-name: ${fadeUpAndDown};
  animation-duration: .4s;
  animation-iteration-count:infinite;
  animation-timing-function: ease-in-out;
`;

export const panel1MeThumbsDown = css`
  background-image: url(${LexiIdeaPanel1MeTDImage});
  animation-name: ${fadeUpAndDown};
  animation-duration: .4s;
  animation-iteration-count:infinite;
  animation-delay: .2s;
`;

export const panel1A1 = css`
  background-image: url(${LexiIdeaPanel1A1Image});
`;
export const panel1A2 = css`
  background-image: url(${LexiIdeaPanel1A2Image});
`;
export const panel1A3 = css`
  background-image: url(${LexiIdeaPanel1A3Image});
`;
export const panel1A1a = css`
  background-image: url(${LexiIdeaPanel1A1aImage});
`;

export const panel2_1 = css`
  background-image: url(${LexiIdeaPanel2_1Image});
`;

export const panel2_2 = css`
  background-image: url(${LexiIdeaPanel2_2Image});
`;

export const panel3a = css`
  background-image: url(${LexiIdeaPanel3aImage});
  animation-name: ${fadeUpAndDown};
  animation-duration: .4s;
  animation-iteration-count:infinite;
`;
export const panel3b = css`
  background-image: url(${LexiIdeaPanel3bImage});
  animation-name: ${fadeUpAndDown};
  animation-duration: .4s;
  animation-iteration-count:infinite;
  animation-delay: .2s;
`;
export const panel3c = css`
  background-image: url(${LexiIdeaPanel3cImage});
`;
export const panel3d = css`
  background-image: url(${LexiIdeaPanel3dImage});
`;
export const panel3e = css`
  background-image: url(${LexiIdeaPanel3eImage});
`;
export const panel3f = css`
  background-image: url(${LexiIdeaPanel3fImage});
`;
export const panel3g = css`
  background-image: url(${LexiIdeaPanel3gImage});
`;

export const captionClass = css`
  color: #2e2e2e;
  border: 1px solid #000000;
  padding: 1vh;
  font-size: 2vh;
  position: absolute;
  top: 5vh;
  left: 2vh;
`;

export const messagingClass = css`
  color: #2e2e2e;
  background-color: #CCCCCC;
  padding: 1.3vh;
  font-size: 2vh;
  position: absolute;
  top: 15vh;
  left: 2vh;
  border-radius: 1vh;
  max-width: 34vh;
`;

export const typeClass = css`
  color: #2e2e2e;
  font-size: 3vh;
  position: absolute;
  top: 10vh;
  width:100%;
  text-align: center;
`;

export const hidden = css`
   display: none;
`;

export const panel4a = css`
  background-image: url(${LexiIdeaPanel4aImage});
  animation-name: ${fadeUpAndDown};
  animation-duration: .4s;
  animation-iteration-count:infinite;
`;
export const panel4b = css`
  background-image: url(${LexiIdeaPanel4bImage});
  animation-name: ${fadeUpAndDown};
  animation-duration: .4s;
  animation-iteration-count:infinite;
  animation-delay: .2s;
`;
export const panel4c = css`
  background-image: url(${LexiIdeaPanel4cImage});
`;
export const panel4d = css`
  background-image: url(${LexiIdeaPanel4dImage});
`;
export const panel4e = css`
  background-image: url(${LexiIdeaPanel4eImage});
`;
export const panel4f = css`
  background-image: url(${LexiIdeaPanel4fImage});
`;
export const panel5 = css`
  background-image: url(${LexiIdeaPanel5Image});
`;
export const panel6 = css`
  background-image: url(${LexiIdeaPanel6Image});
`;
export const panel7a = css`
  background-image: url(${LexiIdeaPanel7aImage});
`;
export const panel7b = css`
  background-image: url(${LexiIdeaPanel7bImage});
`;

export const panel8 = css`
  transition: opacity ease-in-out 0.5s;
`;

export const invisibleImageClass = css`
  opacity: 0;
`;

export const panel8a = css`
  background-image: url(${LexiIdeaPanel8aImage});
`;
export const panel8b = css`
  background-image: url(${LexiIdeaPanel8bImage});
`;
export const panel8c = css`
  background-image: url(${LexiIdeaPanel8cImage});
`;
export const panel8d = css`
  background-image: url(${LexiIdeaPanel8dImage});
`;
export const panel8e = css`
  background-image: url(${LexiIdeaPanel8eImage});
`;
export const panel8f = css`
  background-image: url(${LexiIdeaPanel8fImage});
`;

export const panel9 = css`
  background-image: url(${LexiIdeaPanel9Image});
`;

export const panel10 = css`
  background-image: url(${LexiIdeaPanel10Image});
`;
