import React from 'react'
import PopPopPopLogo from "../Icons/poppoppop-logo";
import imgSrcIphone from "../images/iphone_6.5_010.png";
import imgSrcAppstoreDownload from "../images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import Appstore from "../Icons/appstore";
import Playstore from "../Icons/playstore";
import LexiFooter from "../Components/LexisWorldFooter";


export default function LexisWorld () {
    return (
        <main className={`flex flex-col items-center w-full font-Inter p-3`}>
            <section className={`max-w-xl w-full`}>
                <div className="flex flex-col items-center w-full aspect-video bg-[url('./images/LexisWorldBanner.png')] bg-cover bg-center bg-amber-400 mb-4" />

                <div className="text-left w-full">
                    <p className={`text-base font-semibold w-full`}>Build a world with words</p>

                    <p className={`text-base  w-full mb-4`}>In Lexi's World, words are magic spells that bring their meanings to life. Children can spell words they don't yet know by tapping the keyboard which only lets them make words that work in Lexi's World.</p>

                    <p className={`text-base font-semibold w-full`}>Gentle fun and discovery</p>

                    <p className={`text-base w-full mb-4`}>To get a star, you can simply type the word star! There are discoveries to be made. Once you have a pig, you can type mud — see what happens!</p>

                    <p className={`text-base font-semibold w-full`}>Introduces letters, words and typing</p>

                    <p className={`text-base w-full mb-8`}>A fun way to introduce letters, words, spelling, typing and animals to kids.</p>

                    <a href="https://apps.apple.com/us/app/lexis-world/id1434971401"><div className="mx-auto w-1/2 max-w-lg aspect-[29/64] bg-[url('./images/iphone_6.5_010.png')] bg-cover bg-center bg-amber-400 mb-4 rounded-2xl cursor-pointer" /></a>

                    <div className={"flex flex-col gap-4 pt-4"} >
                        <div className={"w-32 m-auto"} >
                            <a href="https://apps.apple.com/us/app/lexis-world/id1434971401">
                                <Appstore />
                            </a>
                        </div>

                        <div className={"w-32 m-auto"} >
                            <a href="https://play.google.com/store/apps/details?id=info.poppoppop.lexi">
                                <Playstore />
                            </a>
                        </div>
                    </div>

                    <div className={"border-t border-t-grey mt-16 pt-12"}>
                        <p className={`text-base font-semibold w-full`}>Reflection</p>
                        After making the game, I wrote <a href={"https://josh-on.medium.com/lexis-world-what-worked-what-didn-t-and-what-might-c294ca2c6333"} className={"underline"} >a reflection on what worked and what didn't</a>.
                    </div>

                    {/*    FOOTER    */}
                    <LexiFooter />

                </div>


            </section>
        </main>
    )
}