import React from 'react';

export default function LetterO ({
                                     color = '#FC0',
                                     scale = 1,
                                     xOffset = 0,
                                     yOffset = 0
                                 }){
    return (
        <svg className={"w-1/3 aspect-square  m-[-2%] opacity-75"}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" aria-labelledby="title" style={{transform:"scale("+scale+") translate("+xOffset+"%,"+yOffset+"%)", transformOrigin:"50% 50%"}}>
            <title id="title">The letter O</title>
            <circle fill="none" stroke={color} strokeWidth="40" cx="50" cy="50" r="28"></circle>
        </svg>
    )
}