import React from 'react'

export default function CardAFewWords () {
    return<>
    {/*    HEADER IMAGE */}
        <div className="flex flex-col justify-center items-center w-full aspect-video bg-white border-b-black border-b text-4xl font-bold " >A FEW WORDS</div>
        <div className="text-left p-8">
            <span className={`font-semibold`}>A Few Words</span> is a casual word game. The player can slide letters around a grid. When they make a word it disappears. The goal is to clear the grid of letters.</div>

    </>
}