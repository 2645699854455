import React from 'react';

export default function LetterP ({
    color = '#FC0',
    rotation = '44',
    scale = 1,
    xOffset = 0,
    yOffset = 0
  }) {
    return (
        <svg className={"w-1/3 aspect-[2/1] m-[-5.5%] opacity-75"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 150" aria-labelledby="title" style={{transform:"scale("+scale+") rotate("+rotation+"deg) translate("+xOffset+"%,"+yOffset+"%)", transformOrigin:"50% 33%"}}>
          <title id="title">The letter P</title>
          <circle fill="none" stroke={color} strokeWidth="40" cx="50" cy="50" r="28"></circle>
          <path d="M 22 50 L 22 110"  fill="none" stroke={color} strokeWidth="40" strokeLinecap="round" />
        </svg>
    )
}
