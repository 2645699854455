import React from "react";
import AFewWordsFooter from "../Components/AFewWordsFooter";

function AFewWordsPrivacy() {
    return (<main className={`flex flex-col items-center w-full font-Inter p-3`}>
            <section className={`max-w-xl w-full`}>
                <a href={"./a-few-words"}>

                    <div className="flex flex-col items-start justify-center w-full aspect-video mb-4 border-b-black border-b" >
                        <div className={"font-[800] text-5xl text-left"}>A<br/>FEW<br/>WORDS</div>
                    </div>

                </a>

                <div className="text-left w-full">
                    <p className={`text-lg font-semibold w-full mb-4`}>Privacy information for A Few Words</p>

                    <p className={`text-base  w-full mb-4`}>The A Few Words app by Pop Pop Pop LLC has no social network interaction, or ads. It does not collect any information about you.</p>

                    <p className={`text-base  w-full mb-4`}>The app does not collect any information about your location.</p>

                    <p className={`text-base  w-full mb-4`}>The app does store your game history locally on your device.</p>

                </div>
            </section>

            {/*    FOOTER    */}
            <AFewWordsFooter />

        </main>
    );
}

export default AFewWordsPrivacy;
