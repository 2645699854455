import React, { useState, useRef, useEffect } from 'react';
import UseInterval from '../Hooks/UseIntervalHook';
import * as styles from '../LexiIdeaStyles/styled';
import { Scrollama, Step } from 'react-scrollama';
import Appstore from "../Icons/appstore";
import Playstore from "../Icons/playstore";
import PopPopPopLogo from "../Icons/poppoppop-logo";

function LexiIdea() {

    const [p1Interval, setP1Interval] = useState(null);
    const [p1Progress, setP1Progress] = useState(null);
    const [p1Frame, setP1Frame] = useState(null);
    const panel1ProgressBarStyle = {
        width: `${p1Progress}%`
    }

    const [p2StepIndex, setP2StepIndex] = useState(null);
    const [p3TypeText, setP3TypeText] = useState("");

    const [p3Interval, setP3Interval] = useState(null);
    const [p3Progress, setP3Progress] = useState(null);
    const [p3Frame, setP3Frame] = useState(null);
    const panel3ProgressBarStyle = {
        width: `${p3Progress/10}%`
    }

    const [p4Interval, setP4Interval] = useState(null);
    const [p4Progress, setP4Progress] = useState(null);
    const [p4Frame, setP4Frame] = useState(null);
    const panel4ProgressBarStyle = {
        width: `${p4Progress/8}%`
    }

    const [p8Interval, setP8Interval] = useState(null);
    const [p8Progress, setP8Progress] = useState(null);
    const [p8Frame, setP8Frame] = useState(null);
    const panel8ProgressBarStyle = {
        width: `${p8Progress/4}%`
    }

    const [p7StepIndex, setP7StepIndex] = useState(null);
    const panel1Ref = useRef();
    const [msgText, setMsgText] = useState("");
    const [currentText, setCurrentText] = useState("");
    const [p4TypeText, setP4TypeText] = useState("");


    // The timer controlling the animation in Panel 1
    UseInterval(() => {
        setP1Progress(p1Progress + 1);
        let fullText = "";
        let currText = "";
        let textAnimStart = 0;
        let textAnimLength = 0;
        let animFinished = false;
        if(p1Progress < 15){
            fullText = "";
            setP1Frame(1);
        } else if (p1Progress < 55) {
            textAnimStart = 15;
            setP1Frame(2);
            textAnimLength = 30;
            fullText = "At park with kid. \n" + "Will send file soon";
        } else if (p1Progress >= 55) {
            textAnimLength = 40;
            textAnimStart = 55;
            setP1Frame(3);
            fullText = "Coming home in 10 minutes will go past store. Do you want anything?";
        }

        if (p1Progress >= 100) {
            animFinished = true;
            setP1Interval(null);
            currText = fullText;
        }

        if(fullText == ""){
            currText = "";
        } else if (!animFinished) {
            let prg = Math.min(p1Progress - textAnimStart, textAnimLength)/textAnimLength;
            let len = Math.round(fullText.length * Math.min(prg * 1.01, 1));
            currText = fullText.slice(0, len);
        }
        setCurrentText(currText);
    }, p1Interval);

    const onP01StepEnter = ({ data, direction }) => {
        // console.log("onP01StepEnter", data, direction);
        if(direction == "down"){
            setP1Progress(0);
            setP1Interval(80);
        }
    }

    const restartPanel1 = () => {
        setP1Progress(0);
        setP1Interval(80);
    }

    const scrollToFirstPanel = (event) => {
        window.scrollTo({top:panel1Ref.current.offsetTop, left: 0, behavior: "smooth"});
    };

    const onP2StepEnter = ({ data }) => {
        setP2StepIndex(data);
    };


    // The timer controlling the animation in Panel 3
    UseInterval(() => {
        setP3Progress(p3Progress + 1);
        let fullText = "FDGHGFXZXKJKLNB";
        let currText = "";
        let textAnimStart = 0;
        let textAnimLength = 0;
        let animFinished = false;
        if(p3Progress < 50){
            setP3Frame(1);
            fullText = "";
        } else if (p3Progress < 200) {
            textAnimStart = 50;
            setP3Frame(1);
            textAnimLength = 100;
        } else if (p3Progress < 350) {
            setP3Frame(2);
        } else if (p3Progress < 500) {
            setP3Frame(3);
        } else if (p3Progress < 650) {
            setP3Frame(4);
        } else if (p3Progress < 800) {
            setP3Frame(5);
        } else if (p3Progress < 1000) {
            setP3Frame(6);
        }

        if (p3Progress >= 1000) {
            setP3Interval(null); // stops anim
        }

        if (p3Frame == 1 && fullText != "") {
            let prg = Math.min(p3Progress - textAnimStart, textAnimLength)/textAnimLength;
            let len = Math.round(fullText.length * Math.min(prg * 1.01, 1));
            currText = fullText.slice(0, len);
        } else {
            currText = fullText;
        }
        setP3TypeText(currText);
    }, p3Interval);


    const onP3StepEnter = ({ data, direction }) => {
        if(direction == "down"){
            setP3Progress(0);
            setP3Interval(15);
        }
    }

    const restartPanel3 = () => {
        setP3Progress(0);
        setP3Interval(15);
    }


    // The timer controlling the animation in Panel 4
    UseInterval(() => {
        setP4Progress(p4Progress + 1);
        let mtxt0 = "CCCXXXX";
        let mtxt3 = "NQTREF";
        let mtxt6 = "MMLKJVCFD";
        let fullText = "";
        let currText = "";
        let textAnimStart = 0;
        let textAnimLength = 0;
        let animFinished = false;
        if(p4Progress < 50){
            setP4Frame(0);
            fullText = "";
        } else if (p4Progress < 150) {
            setP4Frame(0);
            fullText = mtxt0;
            textAnimStart = 50;
            textAnimLength = 100;
        } else if (p4Progress < 180) { // 150 -180 looks up
            fullText = mtxt0;
            setP4Frame(1);
        } else if (p4Progress < 280) { // 180 - 280 sorry
            fullText = mtxt0;
            setP4Frame(2);
        } else if (p4Progress < 380) { // 280 - 380 typing
            fullText = mtxt3;
            textAnimStart = 280;
            textAnimLength = 100;
            setP4Frame(3);
        } else if (p4Progress < 430) { // 380 - 430 looks up
            fullText = mtxt3;
            setP4Frame(4);
        } else if (p4Progress < 530) { // 430 - 530 sorry
            fullText = mtxt3;
            setP4Frame(5);
        } else if (p4Progress < 630) { //  530 - 630 typing
            setP4Frame(6);
            fullText = mtxt6;
            textAnimStart = 530;
            textAnimLength = 100;
        } else if (p4Progress < 680) { // 630 - 680 looks up
            fullText = mtxt6;
            setP4Frame(7);
        } else if (p4Progress < 800) { //  680 - 800 sorry
            fullText = mtxt6;
            setP4Frame(8);
        }

        if (p4Progress >= 800) {
            fullText = mtxt6;
            setP4Interval(null); // stops anim
        }

        if (p4Frame == 0 || p4Frame == 3 || p4Frame == 6 && fullText != "") {
            let prg = Math.min(p4Progress - textAnimStart, textAnimLength)/textAnimLength;
            let len = Math.round(fullText.length * Math.min(prg * 1.01, 1));
            currText = fullText.slice(0, len);
        } else {
            currText = fullText;
        }
        setP4TypeText(currText);
    }, p4Interval);


    const onP4StepEnter = ({ data, direction }) => {
        if(direction == "down"){
            setP4Progress(0);
            setP4Interval(15);
        }
    }

    const restartPanel4 = () => {
        setP4Progress(0);
        setP4Interval(15);
    }


    const onP7StepEnter = ({ data }) => {
        setP7StepIndex(data);
    }

    // The timer controlling the animation in Panel 8
    UseInterval(() => {
        setP8Progress(p8Progress + 1);
        let animFinished = false;
        if(p8Progress < 100){
            setP8Frame(0);
        }  else if (p8Progress < 200){
            setP8Frame(1);
        }  else if (p8Progress < 300){
            setP8Frame(2);
        }  else if (p8Progress < 400){
            setP8Frame(3);
        }

        if (p8Progress >= 400) {
            setP8Interval(null); // stops anim
        }
    }, p8Interval);

    const onP8StepEnter = ({ data, direction }) => {
        if(direction == "down"){
            setP8Progress(0);
            setP8Interval(15);
        }
    }

    const restartPanel8 = () => {
        setP8Progress(0);
        setP8Interval(15);
    }

    return (
        <div>

            <a href={"./"} className={"cursor-pointer"}><PopPopPopLogo /></a>

    <div className={styles.panelWrapper}>
                <div className={[styles.panelImageClass, styles.panel0Title, styles.clickable].join(" ")}
                     onClick={scrollToFirstPanel}
                ></div>
            </div>

            <div className={[styles.panelWrapper, styles.clickable].join(" ") } ref={panel1Ref} onClick={restartPanel1}>
                <div className={styles.captionClass} >I was always on a keyboard...</div>
                <div className={[styles.messagingClass, (currentText == "")? styles.hidden : ""].join(" ")} >{currentText}</div>
                <div className={[styles.panelImageClass, styles.panel1A1a, (p1Frame == 2)? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel1MeThumbsBG].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel1MeThumbsUp].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel1MeThumbsDown].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel1A2, (p1Frame == 1)? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel1A1, (p1Frame == 2)? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel1A3, (p1Frame >= 3)? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={styles.panelProgressBarWrapper} >
                    <div className={styles.panelProgressBar} style={panel1ProgressBarStyle}></div>
                </div>
                <Scrollama onStepEnter={onP01StepEnter} offset={0.9}>
                    <Step data={1}><div style={{width: '3px', minHeight: '80vh'}}></div></Step>
                </Scrollama>
            </div>

            {/*PANEL 2*/}
            <div className={styles.panelWrapper}>
                <div className={styles.stickyPanel}>
                    <div className={styles.captionClass} >...of one sort or another.</div>
                    <div className={[styles.panelImageClass, styles.panel2_2, (p2StepIndex == 2)? "" : styles.hiddenImageClass].join(" ")}></div>
                    <div className={[styles.panelImageClass, styles.panel2_1].join(" ")}></div>
                </div>
                <Scrollama onStepEnter={onP2StepEnter} offset={0.9}>
                    <Step data={1} >
                        <div style={{width: '0px', minHeight: '80vh'}}></div>
                    </Step>
                    <Step data={2}>
                        <div style={{width: '0px', minHeight: '20vh'}}></div>
                    </Step>
                </Scrollama>
            </div>

            {/*PADDING*/}
            <div style={{width: '3px', minHeight: '82vh'}}></div>

            {/*PANEL 3*/}
            <div className={[styles.panelWrapper, styles.clickable].join(" ")} onClick={restartPanel3}>
                <div className={styles.typeClass} >{p3TypeText}</div>
                <div className={[styles.panelImageClass, styles.panel3a, (p3Frame == 1)? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel3b, (p3Frame == 1)? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel3c, (p3Frame == 2)? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel3d, (p3Frame == 3)? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel3e, (p3Frame == 4)? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel3f, (p3Frame == 5)? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel3g, (p3Frame == 6)? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={styles.panelProgressBarWrapper} >
                    <div className={styles.panelProgressBar} style={panel3ProgressBarStyle}></div>
                </div>
                <Scrollama onStepEnter={onP3StepEnter} offset={0.9}>
                    <Step data={1}><div style={{width: '3px', minHeight: '80vh'}}></div></Step>
                </Scrollama>
            </div>


            {/* PANEL 4 */}
            <div className={[styles.panelWrapper, styles.clickable].join(" ")} onClick={restartPanel4}>
                <div className={styles.typeClass} >{p4TypeText}</div>
                <div className={[styles.panelImageClass, styles.panel4a, (p4Frame == 0 || p4Frame == 3 || p4Frame == 6 )? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel4b, (p4Frame == 0 || p4Frame == 3 || p4Frame == 6 )? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel4c, (p4Frame == 1 || p4Frame == 4 || p4Frame == 7 )? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel4d, (p4Frame == 2 )? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel4e, (p4Frame == 5 )? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel4f, (p4Frame == 8 )? "" : styles.hiddenImageClass].join(" ")}></div>
                <div className={styles.panelProgressBarWrapper} >
                    <div className={styles.panelProgressBar} style={panel4ProgressBarStyle}></div>
                </div>
                <Scrollama onStepEnter={onP4StepEnter} offset={0.9}>
                    <Step data={1}><div style={{width: '3px', minHeight: '80vh'}}></div></Step>
                </Scrollama>
            </div>


            {/* PANEL 5 */}
            <div className={styles.panelWrapper}>
                <div className={[styles.panelImageClass, styles.panel5].join(" ")}></div>
            </div>

            {/* PANEL 6 */}
            <div className={styles.panelWrapper}>
                <div className={[styles.panelImageClass, styles.panel6].join(" ")}></div>
            </div>

            {/* PANEL 7 */}
            <div className={styles.panelWrapper}>
                <div className={[styles.panelImageClass, styles.panel7a].join(" ")}></div>
            </div>

            {/* PANEL 8 */}
            <div className={[styles.panelWrapper, styles.clickable].join(" ")} onClick={restartPanel8}>
                <div className={[styles.panelImageClass, styles.panel8, styles.panel8a, (p8Frame == 0 )? "" : styles.invisibleImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel8, styles.panel8b, (p8Frame == 1 )? "" : styles.invisibleImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel8, styles.panel8c, (p8Frame == 2 )? "" : styles.invisibleImageClass].join(" ")}></div>
                <div className={[styles.panelImageClass, styles.panel8, styles.panel8d, (p8Frame == 3 )? "" : styles.invisibleImageClass].join(" ")}></div>
                <div className={styles.panelProgressBarWrapper} >
                    <div className={styles.panelProgressBar} style={panel8ProgressBarStyle}></div>
                </div>
                <Scrollama onStepEnter={onP8StepEnter} offset={0.9}>
                    <Step data={1}><div style={{width: '3px', minHeight: '80vh'}}></div></Step>
                </Scrollama>
            </div>

            {/* PANEL 8e */}
            <div className={styles.panelWrapper}>
                <div className={[styles.panelImageClass, styles.panel8e].join(" ")}></div>
            </div>

            {/* PANEL 8f */}
            <div className={styles.panelWrapper}>
                <div className={[styles.panelImageClass, styles.panel8f].join(" ")}></div>
            </div>


            {/* PANEL 9 */}
            <div className={[styles.panelWrapper, styles.shortPanelWrapper].join(" ")}>
                ...
            </div>

            <div className={styles.videoWrapper}>
                <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/Hfrdbj1T6gA?color=white&theme=light"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                        allowFullScreen></iframe>
            </div>

            {/* PANEL 10f */}
            <div className={styles.panelWrapper}>
                <div className={[styles.panelImageClass, styles.panel10].join(" ")}></div>
            </div>

            <div className={"flex flex-col gap-4 pt-4 mb-32"} >
                <div className={"w-32 m-auto"} >
                    <a href="https://apps.apple.com/us/app/lexis-world/id1434971401">
                        <Appstore />
                    </a>
                </div>

                <div className={"w-32 m-auto"} >
                    <a href="https://play.google.com/store/apps/details?id=info.poppoppop.lexi">
                        <Playstore />
                    </a>
                </div>
            </div>

        </div>
    );
}

export default LexiIdea;