import React from "react";
import PopPopPopLogo from "../Icons/poppoppop-logo";
import Envelope from "./Envelope";

function AFewWordsFooter() {
    return (
        <div className={"flex flex-col w-full text-xs"}>
        <div className={"flex flex-row justify-between border-y border-black mt-12 py-8"}>
            <div className={`text-xs w-1/2 flex flex-col justify-start gap-1.5 items-start text-left"`}>
                <p className={`text-left`}>© 2018 PopPopPop LLC</p>
                <p className={`text-left hover:underline`}><a href={"./a-few-words-privacy"}>A Few Words Privacy</a></p>
                <p className={`text-left hover:underline`}><a href={"./a-few-words-terms"}>A Few Words Terms of Use</a></p>
            </div>

            <div className={"w-2/5 h-16 flex flex-col items-center justify-center"} >
                <a href={"./"}><PopPopPopLogo /></a>
            </div>
        </div>

            <a href={"mailto:contact@poppoppop.info"} target={"_blank"}>
                <div className={"flex flex-row justify-center items-center gap-3 my-8 cursor-pointer hover:underline"}>
                    <div className={"w-8"}><Envelope /></div>
                    <div>Email contact@poppoppop.info</div>
                </div>
            </a>

        </div>
    );
}

export default AFewWordsFooter;
