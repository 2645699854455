import React from 'react'
import Appstore from "../Icons/appstore";
import Playstore from "../Icons/playstore";
import AFewWordsFooter from "../Components/AFewWordsFooter";


export default function AFewWords () {
    return (
        <main className={`flex flex-col items-center w-full font-Inter p-3`}>
            <section className={`max-w-xl w-full`}>
                <div className="flex flex-col items-start justify-center w-full aspect-video mb-4 border-b-black border-b" >
                    <div className={"font-[800] text-5xl text-left"}>A<br/>FEW<br/>WORDS</div>
                </div>

                <div className="text-left w-full">
                    <p className={`text-base font-semibold w-full`}>Drag letters. Make words. Win.</p>

                    <p className={`text-base  w-full mb-4`}>A Few Words is a casual word game that has a new puzzle every day. There is a grid of letters that the player can slide around to make word. When a word is made it is automatically removed from the grid. The goal of the game is to remove all the letters from the grid.</p>

                    <div className={"flex flex-col gap-4 pt-4"} >
                        <div className={"w-32 m-auto"} >
                            <a href="https://apps.apple.com/app/a-few-words/id6479720159">
                                <Appstore />
                            </a>
                        </div>

                        <div className={"w-32 m-auto"} >
                            <a href="https://play.google.com/store/apps/details?id=info.poppoppop.allclear">
                                <Playstore />
                            </a>
                        </div>

                    </div>


                    {/*    FOOTER    */}
                    <AFewWordsFooter />

                </div>

            </section>
        </main>
    )
}