import React, {useEffect, useState} from 'react';
import LetterP from './letter-p.js';
import LetterO from './letter-o.js';

export default function Pop ({color="#CCC"}) {


    const getRandomRange = (min, max) => {
        return Math.random() * (max - min) + min;
    }

      const [hidden, setHidden] = useState(false);

      // ROTATION
      const [p1RotOffSet, setP1RotOffSet] = useState( Math.random() * 10 - 5);
      const [p2RotOffSet, setP2RotOffSet] = useState(Math.random() * 10 - 5);
      const [p1RotSpeed, setP1RotSpeed] = useState( getRandomRange(0.0006, 0.0006));
      const [p2RotSpeed, setP2RotSpeed] = useState( getRandomRange(0.0003, 0.0006));
      const [p1Rotation, setP1Rotation] = useState( 1);
      const [p2Rotation, setP2Rotation] = useState( 1);

      // SCALE
      const [p1Scale, setP1Scale] = useState( 1);
      const [oScale, setOScale] = useState( .6);
      const [p2Scale, setP2Scale] = useState( 1);
      const [p1ScaleSpeed, setP1ScaleSpeed] = useState( getRandomRange(0.0003, 0.0006));
      const [oScaleSpeed, setOScaleSpeed] = useState( getRandomRange(0.0003, 0.0006));
      const [p2ScaleSpeed, setP2ScaleSpeed] = useState( getRandomRange(0.0003, 0.0006));

      // POSITION
      const [wobbleAmt, setWobbleAmt] = useState( 1.75);

      // X POSITION
      const [p1X, setP1X] = useState(0);
      const [p1XTimeOffset, setP1XTimeOffset] = useState( Math.round(Math.random() * 200));
      const [p1XSpeed, setP1XSpeed] = useState( getRandomRange(0.0003, 0.0006));

      const [oX, setOX] = useState(0);
      const [oXTimeOffset, setOXTimeOffset] = useState( Math.round(Math.random() * 200));
      const [oXSpeed, setOXSpeed] = useState( getRandomRange(0.0003, 0.0006));

      const [p2X, setP2X] = useState(0);
      const [p2XTimeOffset, setP2XTimeOffset] = useState( Math.round(Math.random() * 200));
      const [p2XSpeed, setP2XSpeed] = useState( getRandomRange(0.0003, 0.0006));

      // Y POSITION
      const [p1Y, setP1Y] = useState(0);
      const [p1YTimeOffset, setP1YTimeOffset] = useState( Math.round(Math.random() * 200));
      const [p1YSpeed, setP1YSpeed] = useState( getRandomRange(0.0003, 0.0006));

      const [oY, setOY] = useState(0);
      const [oYTimeOffset, setOYTimeOffset] = useState( Math.round(Math.random() * 200));
      const [oYSpeed, setOYSpeed] = useState( getRandomRange(0.0003, 0.0006));

      const [p2Y, setP2Y] = useState(0);
      const [p2YTimeOffset, setP2YTimeOffset] = useState( Math.round(Math.random() * 200));
      const [p2YSpeed, setP2YSpeed] = useState( getRandomRange(0.0003, 0.0006));

      // const [audio] = useState(new Audio('/pop.mp3'));


  const handleClick = () => {
    //console.log("Handle Click " + document.location.pathname);

    if(document.location.pathname != "/") {
      document.location.href = "./"; // TODO: how to user react router here?
      return;
    }

    // audio.play();

    setHidden(true);
    setTimeout(() => setHidden(false), 1000);

  }

  useEffect (() =>{
    const timerID = setInterval(
      () => tick(),
      10
    );
    // audio.load();
    return function cleanup() {
      clearInterval(timerID);
    };
  }, []);


  const tick = () => {
      setP1Rotation(getWobble(p1RotOffSet, p1RotSpeed));
      setP2Rotation(getWobble(p2RotOffSet, p2RotSpeed));
      setP1Scale(Math.sin(Date.now() * p1ScaleSpeed) * Math.PI * .02 + 1.15);
      setOScale(Math.sin(Date.now() * oScaleSpeed) * Math.PI * .01 + .95);
      setP2Scale(Math.sin(Date.now() * p2ScaleSpeed) * Math.PI * .02 + 1.15);
      setP1X(Math.sin((Date.now() + p1XTimeOffset) * p1XSpeed) * Math.PI * wobbleAmt + wobbleAmt/2);
      setOX(Math.sin((Date.now() + oXTimeOffset) * oXSpeed) * Math.PI * wobbleAmt + wobbleAmt/2);
      setP2X(Math.sin((Date.now() + p2XTimeOffset) * p2XSpeed) * Math.PI * wobbleAmt + wobbleAmt/2);
      setP1Y(Math.sin((Date.now() + p1YTimeOffset) * p1YSpeed) * Math.PI * wobbleAmt + wobbleAmt/2);
      setOY(Math.sin((Date.now() + oYTimeOffset) * oYSpeed) * Math.PI * wobbleAmt + wobbleAmt/2);
      setP2Y(Math.sin((Date.now() + p2YTimeOffset) * p2YSpeed) * Math.PI * wobbleAmt + wobbleAmt/2);
  }

  const getWobble = (base, speed) => {
    const w = base + Math.sin(Date.now() * speed) * Math.PI * 5;
    return w;
  }

    return (
      // <div className={`inline-flex my-0 mx-[.5vw] ease-in-out ${hidden?"transition-[transition:transform_0ms,opacity_0s] opacity-0 scale-10":"transition-[transition:transform_300ms,opacity_1s] opacity-100 scale-110"}`} onClick={handleClick} >
          <div className={`inline-flex ease-in-out w-1/3 cursor-pointer ${hidden
              ?"transition-[transition:transform,opacity] opacity-0 scale-10 duration-0"
              :"transition-[transition:transform] opacity-100 scale-110 duration-500"}
              `} onClick={handleClick} >
        <LetterP color={color} rotation={p1Rotation.toString()} scale={p1Scale}  xOffset={p1X} yOffset={p1Y} />
        <LetterO color={color} scale={oScale} xOffset={oX}  yOffset={oY} />
        <LetterP color={color} rotation={p2Rotation.toString()} scale={p2Scale} xOffset={p2X}  yOffset={p2Y}/>
      </div>
    );
}