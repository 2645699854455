import React from 'react';

export default function Card ({children, link }) {

    function navigateTo (link) {
        window.location.href = link;
    }

    return <div className={"bg-white shadow-2xl rounded-lg w-4/5 max-w-xl cursor-pointer text-base overflow-hidden mb-8"} onClick={() => {navigateTo(link)}}>
        {children}
    </div>
}