import React from "react";
import LexiFooter from "../Components/LexisWorldFooter";

function LexiPrivacy() {
    return (<main className={`flex flex-col items-center w-full font-Inter p-3`}>
            <section className={`max-w-xl w-full`}>
                <a href={"./lexis-world"}><div className="flex flex-col items-center w-full aspect-video bg-[url('./images/LexisWorldBanner.png')] bg-cover bg-center bg-amber-400 mb-4" /></a>

                <div className="text-left w-full">
                    <p className={`text-lg font-semibold w-full mb-4`}>Privacy information for Lexi's World</p>

                    <p className={`text-base  w-full mb-4`}>The Lexi's World app by Pop Pop Pop LLC has no social network interaction, or ads. It does not collect any information about you or your child.</p>

                    <p className={`text-base  w-full mb-4`}>The app does not collect any information about your location.</p>

                    <p className={`text-base  w-full mb-4`}>The app does store your settings choices such as sound options locally on your device.</p>

                </div>
            </section>

            {/*    FOOTER    */}
            <LexiFooter />

        </main>
    );
}

export default LexiPrivacy;
